import { getDataOnRealtimeDb, saveDataOnRealtimeDb } from '../utils/firebase';
import { ExpenseCategory, IncomeSource, BillCategory } from '../utils/Catogory';

class UserInitializationService {
    constructor(userId) {
        this.userId = userId;
        this.basePath = `users/${userId}`;
    }

    async initializeUser() {
        try {
            const initialized = await this.checkInitialization();
            if (initialized) {
                console.log('User already initialized');
                return;
            }

            // Sequential initialization to ensure all data is properly saved
            await this.initializeExpenseCategories();
            await this.initializeIncomeCategories();
            await this.initializeBillCategories();
            await this.initializeSettings();

            await this.markAsInitialized();
            console.log('User initialization complete');
        } catch (error) {
            console.error('Error during user initialization:', error);
            throw error;
        }
    }

    async checkInitialization() {
        const initStatus = await getDataOnRealtimeDb(`${this.basePath}/initialized`);
        return !!initStatus;
    }

    async initializeExpenseCategories() {
        const path = `${this.basePath}/category/expense`;
        const existing = await getDataOnRealtimeDb(path);

        if (!existing) {
            // Save each category individually with a unique key
            const savePromises = ExpenseCategory.map(category => {
                return saveDataOnRealtimeDb(`${path}`, {
                    Category: category.Category,
                });
            });

            await Promise.all(savePromises);
            console.log('Expense categories initialized');
        }
    }

    async initializeIncomeCategories() {
        const path = `${this.basePath}/category/income`;
        const existing = await getDataOnRealtimeDb(path);

        if (!existing) {
            // Save each source individually with a unique key
            const savePromises = IncomeSource.map(source => {
                return saveDataOnRealtimeDb(`${path}`, {
                    Source: source.Source,
                });
            });

            await Promise.all(savePromises);
            console.log('Income sources initialized');
        }
    }

    async initializeBillCategories() {
        const path = `${this.basePath}/category/bill`;
        const existing = await getDataOnRealtimeDb(path);

        if (!existing) {
            const savePromises = saveDataOnRealtimeDb(`${path}`, BillCategory); 
            await Promise.all(savePromises);
            console.log('Bill categories initialized');
        }
    }


    async initializeSettings() {
    const path = `${this.basePath}/settings`;
    const existing = await getDataOnRealtimeDb(path);

    if (!existing) {
        const defaultSettings = {
            notification: {
                email: {
                    enabled: true,
                    frequency: 1
                }
            },
            currency: {
                code: "USD",
                symbol: "$"
            },
            theme: "light"
        };
        await saveDataOnRealtimeDb(path, defaultSettings);
        console.log('Settings initialized');
    }
}

    async markAsInitialized() {
    await saveDataOnRealtimeDb(`${this.basePath}/initialized`, {
        timestamp: new Date().toISOString(),
        status: true
    });
}
}

const createUserInitService = (userId) => new UserInitializationService(userId);

export default createUserInitService; 