// src/utils/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, getAuth, signOut } from 'firebase/auth';
import PropTypes from 'prop-types';
import { getDatabase, ref, onValue } from 'firebase/database';
import app, { getDataOnRealtimeDb } from './firebase'; // Import your firebase config file
import createUserInitService from '../services/userInitService';

const auth = getAuth(app);
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [subscription, setSubscription] = useState(null);

  const handleProfileData = async (user) => {

    if (!user || !currentUser) {
      await logOut();
    }

    try {
      const data = await getDataOnRealtimeDb(`users/${user.uid}/profile`);
      const initialUserData = Object.keys(data).reduce((acc, key) => ({
        id: key,
        name: data[key].name,
        email: data[key].email,
        address: data[key].address || "",
        phoneNumber: data[key].phoneNumber || "",
      }), {});
      return initialUserData;
    } catch {
      console.log("Profile Data does Not Exist");
    }
  }

  const loginAction = (user) => {
    user.getIdTokenResult().then((idTokenResult) => {
      const userToken = idTokenResult.token;
      const expirationTime = Date.now() + 6 * 60 * 60 * 1000; // 6 hours in milliseconds

      localStorage.setItem('tokenExpiration', expirationTime);

      setCurrentUser(user);
      setToken(userToken);
    });
  };

  const logOut = async () => {
    await signOut(auth);
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('token');
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    setCurrentUser(null);
    setSubscription(null);
  };

  const isTokenExpired = () => {
    const expirationTime = localStorage.getItem('tokenExpiration');
    if (!expirationTime) {
      return true;
    }
    return Date.now() > parseInt(expirationTime, 10);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        const initService = createUserInitService(user.uid);
        await initService.initializeUser();

        user.getIdTokenResult().then((idTokenResult) => {
          const userToken = idTokenResult.token;
          const expirationTime = Date.now() + 6 * 60 * 60 * 1000; // 6 hours in milliseconds
          localStorage.setItem('tokenExpiration', expirationTime);
          setCurrentUser(user);
          setToken(userToken);
        });

      } else {
        setCurrentUser(null);
        setSubscription(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isTokenExpired()) {
      logOut();
    }
  }, [token]);

  useEffect(() => {
    const db = getDatabase();
    const subscriptionRef = ref(db, `users/${currentUser?.uid}/subscription`);
    onValue(subscriptionRef, (snapshot) => {
      const subscriptionData = snapshot.val();
      setSubscription(subscriptionData?.status === 'active' ? 'premium' : 'free');
    });
  }, [currentUser]);

  const value = {
    currentUser,
    subscription,
    setSubscription,
    loading,
    loginAction,
    logOut,
    handleProfileData
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);
