
export const ExpenseCategory = [
    { Category: "Housing" },
    { Category: "Utilities" },
    { Category: "Food" },
    { Category: "Transport" },
    { Category: "Healthcare" },
    { Category: "Insurance" },
    { Category: "Education" },
    { Category: "Entertainment" },
    { Category: "Clothing" },
    { Category: "Personal Care" },
    { Category: "Savings/Investments" },
    { Category: "Debt Payments" },
    { Category: "Gifts/Donations" },
    { Category: "Subscriptions" },
    { Category: "Travel" },
    { Category: "Home Maintenance" },
    { Category: "Childcare" },
    { Category: "Pets" },
    { Category: "Miscellaneous" }
];

export const IncomeSource = [
    { Source: "Salary/Wages" },
    { Source: "Bonuses" },
    { Source: "Self-Employment/Business Income" },
    { Source: "Freelance/Contract Work" },
    { Source: "Dividends" },
    { Source: "Interest" },
    { Source: "Capital Gains" },
    { Source: "Rental Income" },
    { Source: "Social Security" },
    { Source: "Unemployment Benefits" },
    { Source: "Disability Benefits" },
    { Source: "Pension/Retirement" },
    { Source: "Royalties" },
    { Source: "Affiliate Marketing" },
    { Source: "Ad Revenue" },
    { Source: "Alimony/Child Support" },
    { Source: "Gifts" },
    { Source: "Inheritance" },
    { Source: "Scholarships/Grants" },
    { Source: "Stipends" },
    { Source: "Lottery Winnings" },
    { Source: "Cash Back/Rewards" },
    { Source: "Reimbursements" }
];

// Define the list of currency options
export const currencyOptions = [
    { value: 'USD', label: 'United States Dollar (USD)', symbol: '$' },
    { value: 'EUR', label: 'Euro (EUR)', symbol: '€' },
    { value: 'GBP', label: 'British Pound Sterling (GBP)', symbol: '£' },
    { value: 'JPY', label: 'Japanese Yen (JPY)', symbol: '¥' },
    { value: 'AUD', label: 'Australian Dollar (AUD)', symbol: 'A$' },
    { value: 'CAD', label: 'Canadian Dollar (CAD)', symbol: 'C$' },
    { value: 'CHF', label: 'Swiss Franc (CHF)', symbol: 'CHF' },
    { value: 'CNY', label: 'Chinese Yuan Renminbi (CNY)', symbol: '¥' },
    { value: 'INR', label: 'Indian Rupee (INR)', symbol: '₹' },
    { value: 'RUB', label: 'Russian Ruble (RUB)', symbol: '₽' },
    { value: 'KRW', label: 'South Korean Won (KRW)', symbol: '₩' },
    { value: 'SGD', label: 'Singapore Dollar (SGD)', symbol: 'S$' },
    { value: 'BRL', label: 'Brazilian Real (BRL)', symbol: 'R$' },
    { value: 'ZAR', label: 'South African Rand (ZAR)', symbol: 'R' },
    { value: 'MXN', label: 'Mexican Peso (MXN)', symbol: '$' },
    { value: 'NZD', label: 'New Zealand Dollar (NZD)', symbol: 'NZ$' },
    { value: 'SEK', label: 'Swedish Krona (SEK)', symbol: 'kr' },
    { value: 'NOK', label: 'Norwegian Krone (NOK)', symbol: 'kr' },
    { value: 'HKD', label: 'Hong Kong Dollar (HKD)', symbol: 'HK$' },
    { value: 'MYR', label: 'Malaysian Ringgit (MYR)', symbol: 'RM' },
    { value: 'TRY', label: 'Turkish Lira (TRY)', symbol: '₺' },
    { value: 'ARS', label: 'Argentine Peso (ARS)', symbol: '$' },
    { value: 'IDR', label: 'Indonesian Rupiah (IDR)', symbol: 'Rp' },
    { value: 'THB', label: 'Thai Baht (THB)', symbol: '฿' },
    { value: 'PHP', label: 'Philippine Peso (PHP)', symbol: '₱' },
    { value: 'AED', label: 'UAE Dirham (AED)', symbol: 'د.إ' },
    { value: 'DKK', label: 'Danish Krone (DKK)', symbol: 'kr' },
    { value: 'PLN', label: 'Polish Złoty (PLN)', symbol: 'zł' },
    { value: 'ILS', label: 'Israeli New Shekel (ILS)', symbol: '₪' },
    { value: 'CLP', label: 'Chilean Peso (CLP)', symbol: '$' },
    { value: 'EGP', label: 'Egyptian Pound (EGP)', symbol: 'E£' },
    { value: 'SAR', label: 'Saudi Riyal (SAR)', symbol: 'ر.س' },
    { value: 'COP', label: 'Colombian Peso (COP)', symbol: '$' },
    { value: 'VND', label: 'Vietnamese Dong (VND)', symbol: '₫' },
    { value: 'KWD', label: 'Kuwaiti Dinar (KWD)', symbol: 'د.ك' },
    { value: 'QAR', label: 'Qatari Riyal (QAR)', symbol: 'ر.ق' },
    { value: 'HUF', label: 'Hungarian Forint (HUF)', symbol: 'Ft' },
    { value: 'CZK', label: 'Czech Koruna (CZK)', symbol: 'Kč' },
    { value: 'UAH', label: 'Ukrainian Hryvnia (UAH)', symbol: '₴' },
    { value: 'BDT', label: 'Bangladeshi Taka (BDT)', symbol: '৳' },
    { value: 'PKR', label: 'Pakistani Rupee (PKR)', symbol: '₨' },
    { value: 'LKR', label: 'Sri Lankan Rupee (LKR)', symbol: 'Rs' },
    { value: 'NGN', label: 'Nigerian Naira (NGN)', symbol: '₦' },
    { value: 'KZT', label: 'Kazakhstani Tenge (KZT)', symbol: '〒' },
    { value: 'PEN', label: 'Peruvian Sol (PEN)', symbol: 'S/' },
    { value: 'BHD', label: 'Bahraini Dinar (BHD)', symbol: 'ب.د' },
    { value: 'KES', label: 'Kenyan Shilling (KES)', symbol: 'KSh' },
    { value: 'MAD', label: 'Moroccan Dirham (MAD)', symbol: 'د.م.' },
    { value: 'OMR', label: 'Omani Rial (OMR)', symbol: 'ر.ع.' },
    { value: 'JOD', label: 'Jordanian Dinar (JOD)', symbol: 'د.أ' },
    { value: 'ISK', label: 'Icelandic Krona (ISK)', symbol: 'kr' },
    { value: 'GEL', label: 'Georgian Lari (GEL)', symbol: '₾' }
];


// Bill
export const BillCategory = {
    utilities: [
      "Electricity",
      "Water",
      "Gas",
      "Internet",
      "Trash Collection"
    ],
    housing: [
      "Rent/Mortgage",
      "Property Taxes",
      "Homeowners Association Fees"
    ],
    transportation: [
      "Car Payment",
      "Fuel",
      "Public Transportation",
      "Car Insurance",
      "Vehicle Maintenance"
    ],
    insurance: [
      "Health Insurance",
      "Life Insurance",
      "Auto Insurance",
      "Home Insurance",
      "Disability Insurance"
    ],
    food: [
      "Groceries",
      "Dining Out",
      "Meal Delivery Services"
    ],
    entertainment: [
      "Streaming Services",
      "Movie/Concert Tickets",
      "Sports/Gym Memberships"
    ],
    loans: [
      "Student Loan Payments",
      "Personal Loan Payments",
      "Credit Card Payments"
    ],
    healthcare: [
      "Medical Bills",
      "Prescription Medications",
      "Doctor Visits"
    ],
    subscriptions: [
      "Magazine/News Subscriptions",
      "Software Subscriptions (e.g., Adobe, Microsoft 365)"
    ],
    education: [
      "Tuition Fees",
      "Books and Supplies",
      "Online Courses"
    ],
    personalExpenses: [
      "Clothing",
      "Haircuts",
      "Gifts"
    ],
    miscellaneous: [
      "Pet Care",
      "Charitable Donations",
      "Miscellaneous Fees"
    ]
  };
  
