// firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, set, push, remove } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const database = getDatabase(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

export const getUserProfile = async (userId) => {
  try {
    const userRef = ref(database, `users/${userId}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      throw new Error('User profile not found');
    }
  } catch (error) {
    throw new Error('Error fetching user profile: ' + error.message);
  }
};

export const saveDataOnRealtimeDb = async (path, newData) => {
  try {
    const userRef = ref(database, `${path}`);
    const newRef = await push(userRef, newData);
    return newRef.key; // Return the generated key
  } catch (error) {
    return new Error('Error saving data profile: ' + error.message);
  }
};


export const getDataOnRealtimeDb = async (path) => {
  try {
    const userRef = ref(database, `${path}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return null;
    }
  } catch (error) {
    return Error('Error saving data profile: ' + error.message);
  }
};


export const deleteDataOnRealtimeDb = async (path, dataId) => {
  try {
    const userRef = ref(database, `${path}/${dataId}`);
    await remove(userRef);
  } catch (error) {
    throw new Error('Failed to delete expense.');
  }
};


export const updateDataOnRealtimeDb = async (path, dataId, updatedData) => {
  try {
    const userRef = ref(database, `${path}/${dataId}`);
    await set(userRef, updatedData);
  } catch (error) {
    throw new Error('Failed to update expense.');
  }
};


export const populateProfileData = async (path, data) => {
  try {
    await saveDataOnRealtimeDb(path, data);
  }
  catch (error) {
    console.log(error)
    throw new Error('Failed to update expense.');
  }
};

export const populateMissingData = async (user) => {
  try {
    // Fetch the existing user profile data
    const existingData = await getDataOnRealtimeDb(`users/${user.uid}/profile`);

    if (existingData != undefined || existingData != null) {

      // Default data to be used if fields are missing
      const defaultData = {
        name: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      };

      // Check if existing data is missing any required fields
      const updatedData = {
        ...defaultData,
        name: existingData.name || defaultData.name,
        email: existingData.email || defaultData.email,
        phoneNumber: existingData.phoneNumber || defaultData.phoneNumber,
      };

      // Save the updated data to Firebase
      await saveDataOnRealtimeDb(`users/${user.uid}/profile`, updatedData);
    } else {

      // Default data to be used if fields are missing
      const defaultData = {
        name: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      };

      await saveDataOnRealtimeDb(`users/${user.uid}/profile`, defaultData);
    }

    const savedData = await getDataOnRealtimeDb(`users/${user.uid}/profile`);
    return Object.keys(savedData).reduce((acc, key) => ({
      id: key,
      name: savedData[key].name,
      email: savedData[key].email,
      address: savedData[key].address || "",
      phoneNumber: savedData[key].phoneNumber || "",
    }), {});

  } catch (error) {
    console.error('Error populating missing data:', error);
    return null; // Return null or handle the error as needed
  }
};

// Add subscription helper functions
export const checkSubscriptionStatus = async (userId) => {
    const subscription = await getDataOnRealtimeDb(`users/${userId}/subscription`);
    return subscription?.status === 'active';
};

export const cancelSubscription = async (userId, subscriptionId) => {
    // Implement PayPal subscription cancellation
    // Update Firebase subscription status
    await updateDataOnRealtimeDb(
        `users/${userId}/subscription`,
        subscriptionId,
        { status: 'cancelled', cancelDate: new Date().toISOString() }
    );
};

export default app;