import { useEffect } from 'react';
import { useAuth } from './AuthContext';
import { getDataOnRealtimeDb, updateDataOnRealtimeDb } from './firebase';

const useSubscriptionCheck = () => {
    const { currentUser, setSubscription } = useAuth();

    useEffect(() => {
        const checkSubscription = async () => {
            if (!currentUser) return;

            try {
                const subscriptionData = await getDataOnRealtimeDb(`users/${currentUser.uid}/subscription`);
                
                if (!subscriptionData) {
                    setSubscription('free');
                    return;
                }

                const { status, expiryDate, subscriptionId } = subscriptionData;

                // Check if subscription has expired
                if (status === 'active' && expiryDate) {
                    const expiry = new Date(expiryDate);
                    const now = new Date();

                    if (expiry < now) {


                        subscriptionData.status = 'expired';
                        subscriptionData.expiryDate = null;
                        subscriptionData.endDate = now.toISOString();

                        await updateDataOnRealtimeDb(
                            `users/${currentUser.uid}`,
                            'subscription',
                            subscriptionData
                        )

                        setSubscription('free');
                    } else {
                        // Subscription is still active
                        setSubscription('premium');

                        // Set up expiry timeout
                        const timeUntilExpiry = expiry.getTime() - now.getTime();
                        
                        // Check 24 hours before expiry
                        const checkBeforeExpiry = setTimeout(() => {
                            checkSubscription();
                        }, Math.max(0, timeUntilExpiry - 86400000)); // 24 hours in milliseconds

                        return () => clearTimeout(checkBeforeExpiry);
                    }
                } else if (status === 'expired') {
                    setSubscription('free');
                } else {
                    // Handle other statuses (cancelled, suspended, etc.)
                    setSubscription('free');
                }
            } catch (error) {
                console.error('Error checking subscription:', error);
                setSubscription('free');
            }
        };

        checkSubscription();
        // Check subscription status every 12 hours
        const intervalCheck = setInterval(checkSubscription, 43200000);
        
        return () => clearInterval(intervalCheck);
    }, [currentUser, setSubscription]);
};

export default useSubscriptionCheck; 