// src/routes/ThemeRoutes.js

import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import PrivateRoute from './PrivateRoute'; // Import your PrivateRoute component

/****Layouts*****/
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));

/***** Functional  Pages ****/
const Bill = Loadable(lazy(() => import('../views/bill/BillPage')));
const Expense = Loadable(lazy(() => import('../views/expense/ExpensePage')));
const Income = Loadable(lazy(() => import('../views/income/IncomePage')));
const Goal = Loadable(lazy(() => import('../views/goal/GoalPage')));
const Profile = Loadable(lazy(() => import('../views/profile/Profile')));
const Dashboard1 = Loadable(lazy(() => import('../views/dashboards/v1/Dashboard')));
const FeatureOrBug = Loadable(lazy(() => import('../views/feature/BugFeatureReportForm')));
const Budget = Loadable(lazy(() => import('../views/budget/BudgetPage')));
const SubscriptionPage = Loadable(lazy(() => import('../views/subscription/SubscriptionPage')));

/***** Apps ****/
const Notes = Loadable(lazy(() => import('../views/apps/notes/Notes')));
const Calendar = Loadable(lazy(() => import('../views/apps/calendar/CalendarApp')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const Register = Loadable(lazy(() => import('../views/auth/Register')));
const Login = Loadable(lazy(() => import('../views/auth/Login')));
const EmailConfirmationPage = Loadable(lazy(() => import('../views/auth/EmailConfirmationPage')));
const PasswordResetPage = Loadable(lazy(() => import('../views/auth/PasswordResetPage')));
const ForgotPasswordPage = Loadable(lazy(() => import('../views/auth/ForgotPasswordPage')));

/*****Routes******/
const ModeHandler = Loadable(lazy(() => import('./ModeHandler')));

/***** AI *******/
const ForcastPage = Loadable(lazy(() => import('../views/ai/ForcastPage')));
const FinancialAdvicePage = Loadable(lazy(() => import('../views/ai/FinancialAdvicePage')));
const DebtPage = Loadable(lazy(() => import('../views/ai/DebtStrategy')));
const ChatInterface = Loadable(lazy(() => import('../views/ai/ChatInterface')));

/***** Reports ****/
const FinancialReports = Loadable(lazy(() => import('../views/reports/FinancialReports')));
const DocumentUpload = Loadable(lazy(() => import('../views/documents/DocumentUpload')));

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', name: 'Demographical', exact: true, element: <PrivateRoute element={<Dashboard1 />} /> },

      { path: '/apps/notes', name: 'Notes', exact: true, element: <PrivateRoute element={<Notes />} /> },
      { path: '/apps/calendar', name: 'Calendar', element: <PrivateRoute element={<Calendar />} /> },

      { path: '/bill', name: 'Bill', element: <PrivateRoute element={<Bill />} /> },
      { path: '/expense', name: 'Expense', element: <PrivateRoute element={<Expense />} /> },
      { path: '/income', name: 'Income', element: <PrivateRoute element={<Income />} /> },
      { path: '/goal', name: 'Goal', element: <PrivateRoute element={<Goal />} /> },
      { path: '/profile', name: 'Profile', element: <PrivateRoute element={<Profile />} /> },
      { path: '/budget', name: 'Budget', element: <PrivateRoute element={<Budget />} /> },
      { path: '/subscription', name: 'Subscription', element: <PrivateRoute element={<SubscriptionPage />} /> },
      { path: '/contact-us', name: 'Contact Us', element: <PrivateRoute element={<FeatureOrBug />} /> },
      { path: '/forcast', name: 'Forcast Expense', element: <PrivateRoute element={<ForcastPage />} /> },
      { path: '/financial-advice', name: 'Financial Advice', element: <PrivateRoute element={<FinancialAdvicePage />} /> },
      { path: '/debt-repayment-strategy', name: 'Debt Repayment Strategy', element: <PrivateRoute element={<DebtPage />} /> },
      { path: '/track-multiple-goals', name: 'Track Multiple Goals', element: <PrivateRoute element={<ForcastPage />} /> },
      { path: '/chat', name: 'AI Assistent', element: <PrivateRoute element={<ChatInterface />} />},
      { path: '/reports/financial', name: 'Financial Reports', element: <PrivateRoute element={<FinancialReports />} />},
      { path: '/documents', name: 'Document Scanner', element: <PrivateRoute element={<DocumentUpload />} />},
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'register', element: <Register /> },
      { path: 'login', element: <Login /> },
      { path: 'resetPassword', element: <PasswordResetPage /> },
      { path: 'forgetPassword', element: <ForgotPasswordPage /> },
      { path: 'verifyEmail', element: <EmailConfirmationPage /> },
      { path: '*', element: <ModeHandler /> },
    ],
  },

];

export default ThemeRoutes;
